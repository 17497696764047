import React from "react"
import Container from '../components/container'
import Layout from "../components/layout"
import PageHeader from '../components/page/header'
import Seo from '../components/seo'

const NotFoundPage = () => {
  const localizedUrls = {
    de: '/',
    en: '/'
  }

  return (
    <Layout locale={'de'} localizedUrls={localizedUrls}>
      <Seo title='Seite nicht gefunden' />
      <PageHeader title="Seite nicht gefunden" />
      <Container>Seite nicht gefunden. Die von dir aufgerufene Seite existiert nicht mehr.</Container>
    </Layout>
  )
}

export default NotFoundPage
